import React, { FunctionComponent, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { FormatPhoneNumber } from '../utilities/formatters';
import { ResendSMSCode, ValidateSMSCode } from '../api/sms-api';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },
    heading: {
        marginBottom: theme.spacing(3),
        alignItems: 'left !important',
    },
    resend: {
        cursor: 'pointer',
        color: '#00C389',
        fontWeight: 700
    },
    submit: {
        margin: theme.spacing(1, 0, 2),
    }
}));

const TextConfirmation: FunctionComponent<{ demoId?: string | null, phone?: string, onTextConfirmationSuccessful: (smsCode:number) => void }> = ({demoId, phone, onTextConfirmationSuccessful }) => {
    const classes = useStyles();
    const [smsCode, setSmsCode] = useState<number>(0);
    const [resendButtonText, setResendButtonText] = useState<string>("Resend the code");
    const [invalidCode, setInvalidCode] = useState<boolean>(false);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSmsCode(parseInt(event.target.value.replace(/\D/,'')));
    };

    const handleResendSMSCodeClick = async (event: any) => {
        if(demoId && phone) {
            setResendButtonText("Resending the code");
            await ResendSMSCode(demoId, phone);
            setResendButtonText("Code re-sent");
            setTimeout(() => { setResendButtonText("Resend the code"); }, 4000);
        }
    };

    const handleSubmitClick = async () => {
        
        if(demoId) {
            const smsIsValid = await ValidateSMSCode(demoId, smsCode);
            if(smsIsValid) {
                onTextConfirmationSuccessful(smsCode);
            }
            else {
                setInvalidCode(true);
            }
        }
    };

    return (
        <React.Fragment>
            <Container className={classes.paper} maxWidth="xs">
                <Typography component="h1" variant="h5" className={classes.heading}>
                    Check your phone.
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box>
                            A text message was just sent to <strong>{FormatPhoneNumber(phone)}.</strong>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <span className={classes.resend} onClick={handleResendSMSCodeClick} >{resendButtonText}</span>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="one-time-code"
                            placeholder="Enter Verification Code"
                            name="one-time-code"
                            autoComplete="one-time-code"
                            onChange={handleInputChange}
                            helperText={invalidCode ? "Invalid Code" : ""}
                            error={invalidCode}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box left={0}>
                            <Button
                                type="submit"
                                variant="contained"
                                className={classes.submit}
                                onClick={handleSubmitClick}
                            >
                                Confirm
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment>
    );
}

export default TextConfirmation;