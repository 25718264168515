import React, { FunctionComponent } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },
    heading: {
        marginBottom: theme.spacing(3),
        alignItems: 'left !important',
    },
    instructions: {
        paddingTop: '0 !important'
    },
    submit: {
        margin: theme.spacing(1, 0, 2),
    }
}));

const InstructionSet: FunctionComponent<{onStartDemo: () => void }> = ({onStartDemo}) => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Container className={classes.paper} maxWidth="xs">
                <Typography component="h1" variant="h5" className={classes.heading}>
                    What to expect.
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                            <img src="/assets/avatar1.png" alt="Maintenance Technician"></img>
                    </Grid>
                    <Grid item xs={8}>
                        <Box>
                            In this demo, you will act as the On-Call Maintenance Technician. 
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <img src="/assets/avatar2.png" alt="Resident"></img>
                    </Grid>
                    <Grid item xs={8}>
                        <Box>                            
                            You will receive an emergency call from your Resident, Jane.
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography component="h2" variant="h5">
                            Next steps
                        </Typography>
                    </Grid> 
                    <Grid item xs={12} className={classes.instructions} >
                        <Box>
                            <ul>
                                <li>Press the "Start Demo" button below</li>
                                <li>You will receive a call and text message</li>
                                <li>Answer the call, then tap the link in the text message </li>
                                <li>You will be guided through the on-call experience</li>
                            </ul>
                            🚨&nbsp;&nbsp;Don’t worry... this is just a simulated call!
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box left={0}>
                            <Button
                                type="submit"
                                variant="contained"
                                className={classes.submit}
                                onClick={onStartDemo}
                            >
                                Start Demo
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment>
    );
}

export default InstructionSet;