import React, { FunctionComponent, useState } from 'react';
import Registration from './components/registration';
import TextConfirmation from './components/textconfirmation';
import CallSimulator from './components/callsimulator';
import RequestInfo from './components/request-info';
import Calling from './components/calling';
import { DemoState, IUser } from './types';
import { SendSMSCode } from './api/sms-api';

const DemoContainer: FunctionComponent = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    const [demoId, setDemoId] = useState<string | null>(params.get('demoid'));
    const [referrer] = useState<string | null>(params.get('ref'));
    const [mode] = useState<string>(params.get('mode') || 'demo');
    const [demoState, setDemoState] = useState<number>(demoId !== null ? DemoState.DemoCompleted : DemoState.Preregistration);
    const [smsCode, setSmsCode] = useState<number>(0);
    const [user, setUser] = useState<IUser>();

    const registrationCompleteHandler = async (user: IUser) => {
        setUser(user);
        setDemoState(DemoState.PendingConfirmation);
        setDemoId(await SendSMSCode(user.name, user.phone));
    }

    const validationCompleteHandler = (smsCode: number) => {
        setDemoState(DemoState.Introduction);
        setSmsCode(smsCode);
    };

    const callCompleted = (callId: string) => {
        if(mode !== 'training') {
            setTimeout(() => {
                setDemoState(DemoState.DemoInProgress);
            }, 15000);
        }
        console.log(callId);
    };

    switch (demoState) {
        case DemoState.Preregistration:
            return (
                <Registration onRegistationComplete={registrationCompleteHandler}></Registration>
            );
        case DemoState.PendingConfirmation:
            return (
                <TextConfirmation demoId={demoId} onTextConfirmationSuccessful={validationCompleteHandler} phone={user?.phone}></TextConfirmation>
            );
        case DemoState.Introduction:
            if(!demoId) 
                return <></>;
                
            return (
                <CallSimulator demoId={demoId} referrer={referrer} mode={mode} smsCode={smsCode} demoState={demoState} onDemoStateChanged={setDemoState} onCallCompleted={callCompleted} ></CallSimulator>
            );
        case DemoState.Calling:
            return (
                <Calling></Calling>
            );
        case DemoState.DemoInProgress:
        case DemoState.DemoCompleted:
            return (
                <RequestInfo></RequestInfo>
            );
        default:
            break;
    }

    return (<></>);
}

export default DemoContainer;