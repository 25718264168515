export interface IEnvironment {
    NAME: string,
    API: string,
    APIKEY: string
}

export interface IUser {
    name?: string,
    phone?: string,
    email?: string
}


export enum DemoState {
    Preregistration,
    PendingConfirmation,
    Introduction,
    Calling,
    DemoInProgress,
    DemoCompleted
}

export interface CallDetailsResponse {
    status: string;
    callDetails: CallDetails;
}

export interface CallDetails {
    Id: string;
    Status: string;
    Notifications?: (null)[] | null;
    Activities?: (ActivitiesEntity)[] | null;
}

export interface ActivitiesEntity {
    Type: string;
    Actor: string;
    Time: string;
    Id: string;
    Recipient?: string | null;
    Text?: string | null;
}
