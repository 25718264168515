import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import DemoContainer from './democontainer';
import Copyright from './components/copyright';
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import './App.css';

const theme = createTheme({
  typography: {
    fontFamily: "proxima-nova, Proxima Nova ,Lucida Grande,Segoe UI,Segoe,Lucida Sans,Lucida Sans Unicode,Verdana,sans-serif"
  },
  palette: {
    primary: {
      main: "#00C389",
    },
    secondary: {
      main: "#00C389",
    },
  },
});

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <div className="App">
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box mt={3} display="flex" justifyContent="center" alignItems="center">
            <img src="/assets/logo.png" alt="Call Complete Logo" />
          </Box>
          <DemoContainer></DemoContainer>
          <Box mt={5}>
            <Copyright />
          </Box>
        </Container>
      </div>
    </MuiThemeProvider>
  );
}

export default App;
