import React, { FunctionComponent } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },
    heading: {
        marginBottom: theme.spacing(3),
        alignItems: 'left !important',
    },
    instructions: {
        paddingTop: '0 !important'
    },
    submit: {
        margin: theme.spacing(1, 0, 2),
    }
}));

const Calling: FunctionComponent<{}> = () => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Container className={classes.paper} maxWidth="xs">
                <Typography component="h1" variant="h5" className={classes.heading}>
                    Check your phone.
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box>
                            <p>You should receive a call along with a text message.</p>
                            <p>The call is to “wake up” and alert the On-Call Maintenance Technician. </p>
                            <p>The text message will include a link to listen to the maintenance request from the resident.</p>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment>
    );
}

export default Calling;