import React, { FunctionComponent, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { IUser } from '../types';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    heading: {
        marginBottom: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(1, 0, 2),
    }
}));

const Registration: FunctionComponent<{ onRegistationComplete: (user: IUser) => void }> = ({ onRegistationComplete }) => {
    const classes = useStyles();
    const [formUser, setUser] = useState<IUser>();
    const [nameIsValid, setNameIsValid] = useState<boolean>(true);
    const [phoneIsValid, setPhoneIsValid] = useState<boolean>(true);

    const handleNameInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.value;
        if(name.length > 0) {
            setNameIsValid(true);
        }
        setUser({ ...formUser, ...{ "name": name } });
    };

    const handlePhoneInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const digits = event.target.value.replace(/\D/g, '');
        const x = digits.match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        if(x){
            event.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        }
        if(digits.length === 10) {
            setPhoneIsValid(true);
        }
        setUser({ ...formUser, ...{ "phone": digits } });
    };

    const validateForm = (formUser: IUser | undefined, setNameIsValid: React.Dispatch<React.SetStateAction<boolean>>, setPhoneIsValid: React.Dispatch<React.SetStateAction<boolean>>) => {
        if (formUser && formUser.name && formUser.name.length > 0) {
            setNameIsValid(true);
        }
        else {
            setNameIsValid(false);
        }

        if (formUser && formUser.phone) {
            const digits = formUser.phone.replace(/\D/g, '');
            setPhoneIsValid(digits?.length === 10);
        }
        else {
            setPhoneIsValid(false);
        }
    }

    const handleSubmitClick = async () => {

        validateForm(formUser, setNameIsValid, setPhoneIsValid);

        if (phoneIsValid && nameIsValid && formUser) {
            onRegistationComplete(formUser);
        }
    };

    return (
        <React.Fragment>
            <Container className={classes.paper} maxWidth="xs">
                <Typography component="h1" variant="h5" className={classes.heading}>
                    Welcome to your Call Complete demo!
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box marginBottom={"10px"}>Call Complete is fully mobile without the need to download any apps.</Box>
                        <Box marginBottom={"10px"}>For the demo, we need your mobile number so we can send you a phone call and a text message to simulate an incoming request to your on-call maintenance technician.</Box>
                        <Box marginBottom={"10px"}>🚨&nbsp;&nbsp;Don’t worry, we don’t keep your details!</Box>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            name="name"
                            placeholder="Full Name"
                            type="text"
                            id="name"
                            autoComplete="name"
                            onChange={handleNameInputChange}
                            helperText={!nameIsValid ? "Please enter your name" : ""}
                            error={!nameIsValid}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            name="phone"
                            placeholder="Mobile Phone Number"
                            type="phone"
                            id="phone"
                            autoComplete="tel"
                            onChange={handlePhoneInputChange}
                            helperText={!phoneIsValid ? "Please enter a valid phone number eg. (555) 555-5555" : ""}
                            error={!phoneIsValid}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box left={0}>
                        <Button
                            type="submit"
                            variant="contained"
                            className={classes.submit}
                            onClick={handleSubmitClick}                    
                            >
                            Submit
                        </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment>
    );
}

export default Registration;
