import { CallDetails, CallDetailsResponse } from "../types";
import {ReadVariables} from '../utilities/environment';

export async function InitiateDemoCall(demoId:string, mode:string, smsCode:number, referrer?: string | null) {
    const env = await ReadVariables();
    let callId: string = '';
    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-api-key': env.APIKEY
        },
        body: JSON.stringify({demoId, mode, smsCode, referrer})
    };
    try {
        const fetchResponse = await fetch(`${env.API}initiate-demo-call`, settings);
        const data = JSON.parse(await fetchResponse.json());
        callId = data.callId;
    } catch (e) {
        console.log(e);
    }  

    return callId;
}

export async function GetCallDetail(demoId:string) {
    const env = await ReadVariables();
    let callDetails: CallDetails = { Id: "", Status: "" };
    const settings = {
        method: 'GET',
        headers: {
            'x-api-key': env.APIKEY
        }
    };
    try {
        const fetchResponse = await fetch(`${env.API}get-call-details/${demoId}`, settings);
        const callDetailsResponse: CallDetailsResponse = JSON.parse(await fetchResponse.json());
        callDetails = callDetailsResponse.callDetails;
    } catch (e) {
        console.log(e);
    }  

    return callDetails;
}