import React, { FunctionComponent } from 'react';
import { DemoState } from '../types';
import InstructionSet from './instructionset';
import { InitiateDemoCall } from '../api/demo-api';

interface CallSimulatorProps{
    demoState?: DemoState;
    demoId: string;
    referrer?: string | null;
    mode: string;
    smsCode: number;
    onDemoStateChanged: (demoState: DemoState) => void;
    onCallCompleted: (callid: string) => void;
 }

const CallSimulator: FunctionComponent<CallSimulatorProps> = ({demoId, referrer, mode, smsCode, demoState, onDemoStateChanged, onCallCompleted}) => {

    const onDemoStart = async () => {
        onDemoStateChanged(DemoState.Calling);
        let callId = await InitiateDemoCall(demoId, mode, smsCode, referrer);
        onCallCompleted(callId);
    };

    return (
    <>
        <InstructionSet onStartDemo={onDemoStart}></InstructionSet>
    </>
    );
}

export default CallSimulator;