import {ReadVariables} from '../utilities/environment';

async function APIPost(url: string, data: any) {
    const env = await ReadVariables();
    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-api-key': env.APIKEY
        },
        body: JSON.stringify(data)
    };

    const fetchResponse = await fetch(url, settings);
    return await fetchResponse.json();
}

export async function SendSMSCode(name?:string, phoneNumber?:string) {
    const env = await ReadVariables();
    try {
        const data = {name, phoneNumber};
        const { demoId } =  JSON.parse(await APIPost(`${env.API}send-invitation-code`, data));
        return demoId;
    } catch (e) {
        console.log(e);
    }  
    return null;
}

export async function ResendSMSCode(demoId?:string, phoneNumber?:string) {
    const env = await ReadVariables();
    try {
        const data = {demoId, phoneNumber};
        const result =  JSON.parse(await APIPost(`${env.API}resend-invitation-code`, data));
        return result;
    } catch (e) {
        console.log(e);
    }  
    return null;
}

export async function ValidateSMSCode(demoId:string, smsCode:number) {
    const env = await ReadVariables();
    let result: boolean = false;
    try {
        const data = {demoId, smsCode};
        const {status} =  JSON.parse(await APIPost(`${env.API}validate-invitation-code`, data));

        console.log(data);
        result = status === 'success';
    } catch (e) {
        console.log(e);
    }  

    return result;
}
