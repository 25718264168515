import React, { FunctionComponent } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },
    heading: {
        marginBottom: theme.spacing(3),
        alignItems: 'left !important',
    },
    submit: {
        margin: theme.spacing(1, 0, 2),
    }
}));

const RequestInfo: FunctionComponent<{}> = () => {
    const classes = useStyles();

    const handleSubmitClick = async () => {
        window.location.href = 'https://happyco.chilipiper.com/book/me/michael-brand?type=call-complete';
    };
    
    return (
        <React.Fragment>
            <Container className={classes.paper} maxWidth="xs">
                <Typography component="h1" variant="h5" className={classes.heading}>
                    What else can Call Complete do?
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box>Schedule a full demo to learn how Call Complete can help you:</Box>
                        <Box marginBottom={"10px"}>
                            <ul>
                                <li>Capture all unanswered calls during or after business hours</li>
                                <li>Trigger email notifications for quicker response times to prospects and residents</li>
                                <li>Easily create and update on-call schedules for your maintenance team</li>
                                <li>Automate your call routing and resident communications</li>
                                <li>Provide intuitive tools for your residents, on-call technicians and courtesy officers to communicate emergency requests</li>
                            </ul>
                        </Box>
                        <Box marginBottom={"10px"}>
                            Make the switch to a smarter answering service.
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box left={0}>
                            <Button
                                type="submit"
                                variant="contained"
                                className={classes.submit}
                                onClick={handleSubmitClick}
                            >
                                Contact Sales
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <img src="/assets/sales_user.png" alt="sales user"></img>
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment>
    );
}

export default RequestInfo;